<template>
  <div class="countDown">
    <div :endTime="endTime" :endText="endText">
      <slot>{{ content }} </slot>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      content: '',
      isShowEmpty: false,
      day: '',
      hour: '',
      min: '',
      sec: ''
    }
  },
  props: {
    endTime: { type: String, default: '' },
    endText: {
      type: String,
      default: 'ۋاقىتىڭىز تولدى نەمەسە ەرەكشە مۇشە بولمادىڭىز!'
    }
  },
  watch: {
    endTime () {
      this.countdowm(this.endTime)
    }
  },
  created () {
    this.countdowm(this.endTime)
  },
  methods: {
    countdowm (timestamp) {
      const self = this
      const timer = setInterval(function () {
        const nowTime = new Date()
        const endTime = new Date(timestamp * 1000)
        const t = endTime.getTime() - nowTime.getTime()
        if (t > 0) {
          const day = Math.floor(t / 86400000)
          let hour = Math.floor((t / 3600000) % 24)
          let min = Math.floor((t / 60000) % 60)
          let sec = Math.floor((t / 1000) % 60)
          hour = hour < 10 ? '0' + hour : hour
          min = min < 10 ? '0' + min : min
          sec = sec < 10 ? '0' + sec : sec
          let format = ''
          if (day > 0) {
            format = `${day} كۇن ${hour} ساعات ${min} مينۋت ${sec} سەكۋنت `
          }
          if (day <= 0 && hour > 0) {
            format = `${hour} ساعات ${min} مينۋت ${sec} سەكۋنت `
          }
          if (day <= 0 && hour <= 0) {
            format = `${min} مينۋت ${sec} سەكۋنت `
          }
          self.content = format
        } else {
          clearInterval(timer)
          self.content = self.endText
        }
      }, 0)
    }
  }
}
</script>

<style lang="less" scoped>
.countDown {
  direction: rtl;
  font-size: 28px;
  text-align: center;
  background-color: #fff;
  // color: #ee0a24;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 8px 12px #ebedf0;
}
</style>
